<template>
  <app-layout>
    <template v-slot:navigationLeftDrawer>
      <projects-drawer></projects-drawer>
    </template>
  </app-layout>
</template>

<script>
import { baseHomeMixin } from "@/mixins/shared/base/home/baseHomeMixin";
export default {
  name: "ProjectsHome",
  mixins: [baseHomeMixin],
  components: {
    ProjectsDrawer: () => import("@/views/projects/ProjectsDrawer")
  },
  computed: {
    selectApplicationName() {
      return this.applicationNames.Projects;
    }
  }
};
</script>
